import styled from 'styled-components'
import PageSection from 'components/PageSection'
import { useWeb3React } from '@pancakeswap/wagmi'
import useTheme from 'hooks/useTheme'
import Container from 'components/Layout/Container'
import { PageMeta } from 'components/Layout/Page'
import { useTranslation } from '@pancakeswap/localization'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useRouter } from 'next/router'
import Hero from './components/Hero'
import SalesSection1 from './components/SalesSection1'
import Footer from './components/Footer'
import SalesSection2 from './components/SalesSection2'

const StyledHeroSection = styled(PageSection)`
  padding-top: 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 48px;
  }
`

const Home: React.FC<React.PropsWithChildren> = () => {
  const HomeSectionContainerStyles = { margin: '0', width: '100%', maxWidth: '968px' }
  const router = useRouter()
  router.replace('/swap')
  return null

  return (
    <>
      <PageMeta />
      <StyledHeroSection
        innerProps={{ style: { margin: '0', width: '100%' } }}
        index={1}
        hasCurvedDivider={false}
        mt="-1px"
      >
        <Hero />
      </StyledHeroSection>

      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background="#fff"
        index={2}
        hasCurvedDivider={false}
        mt="-1px"
      >
        <SalesSection1 />
      </PageSection>

      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background="url(/images/home/bg2.jpg)"
        index={3}
        hasCurvedDivider={false}
        mt="-1px"
      >
        <SalesSection2 />
      </PageSection>
    </>
  )
}

export default Home
