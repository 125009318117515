import { SWRConfig } from 'swr'
import Home from 'views/Home'

const IndexPage = ({ totalTx30Days, addressCount30Days, tvl, liteSupply, marketCap }) => {
  return (
    <SWRConfig
      value={{
        fallback: {
          totalTx30Days,
          addressCount30Days,
          tvl,
          liteSupply,
          marketCap,
        },
      }}
    >
      <Home />
    </SWRConfig>
  )
}

IndexPage.chains = []
export default IndexPage
