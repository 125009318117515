import { ChainId, WETH9 } from '@pancakeswap/sdk'
import {
  Flex,
  MarketCapIcon,
  SupplyIcon,
  TotalValueLockedIcon,
  useMatchBreakpoints,
  useTooltip,
  Text,
  Box,
  Button,
} from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import useTheme from 'hooks/useTheme'
import useSWRImmutable from 'swr/immutable'
import styled from 'styled-components'
import formatLocalisedCompactNumber, { getBalanceNumber } from 'utils/formatBalance'
import { multicallv2 } from 'utils/multicall'
import erc20ABI from 'config/abi/erc20.json'
import { useSlowRefreshEffect } from 'hooks/useRefreshEffect'
import { CAKE, USDT } from '@pancakeswap/tokens'
import { usePriceByPairs } from 'hooks/useBUSDPrice'
import { useState } from 'react'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import IconCard, { IconCardData } from './IconCard'
import StatCardContent from './MetricsSection/StatCardContent'

const BgWrapper = styled.div`
  z-index: -1;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0px;
  background-image: url('/images/home/bg.jpg');
  background-size: cover;
`

const Hero = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()

  const [liteSupply, setLiteSupply] = useState(0)

  useSlowRefreshEffect(() => {
    const fetch = async () => {
      const [totalSupply] = await multicallv2({
        abi: erc20ABI,
        calls: [{ address: CAKE[ChainId.BSC].address, name: 'totalSupply' }],
        chainId: ChainId.BSC,
      })

      setLiteSupply(getBalanceNumber(new BigNumber(totalSupply || BIG_ZERO)))
    }
    fetch()
  }, [])

  const price = usePriceByPairs(USDT[ChainId.BSC], CAKE[ChainId.BSC])
  const liteSupplyString = liteSupply ? formatLocalisedCompactNumber(liteSupply) : '-'

  return (
    <>
      <BgWrapper />
      <Flex
        position="relative"
        flexDirection="column"
        alignItems={['center', null, null, 'center']}
        justifyContent="center"
        mt={['30px', null, '30px']}
        mb={['30px', '30px', null, '250px']}
        id="homepage-hero"
      >
        <Flex flexDirection="column" alignItems="center">
          <Text fontSize={['25px', null, null, '55px']} fontWeight={800} color="primary">
            LITESWAP PROTOCOL
          </Text>
          <Text fontSize={['14px', null, null, '42px']} fontWeight={400}>
            Bringing a Comprehensive Ecosystem
          </Text>
        </Flex>

        <Flex
          flexDirection={['column', null, null, 'row']}
          justifyContent="space-evenly"
          alignItems="center"
          borderRadius={['10px', null, null, '16px']}
          mt={['20px', null, '40px']}
          py={['30px', null, null, '53px']}
          width={['100%', null, null, '80%']}
          style={{
            background: 'rgba(255, 255, 255, 0.04)',
            backdropFilter: 'blur(10px)',
          }}
        >
          <Flex alignItems="center" style={{ gap: '10px' }} py={['5px', null, null, '10px']}>
            <Text fontSize={['12px', null, null, '22px']} fontWeight={200} color="textSubtle">
              Total Supply
            </Text>
            <Text fontSize={['14px', null, null, '28px']} fontWeight={800}>
              {liteSupply}
            </Text>
          </Flex>

          <Flex alignItems="center" style={{ gap: '10px' }} py={['5px', null, null, '10px']}>
            <Text fontSize={['12px', null, null, '22px']} fontWeight={200} color="textSubtle">
              Price
            </Text>
            <Text fontSize={['14px', null, null, '28px']} fontWeight={800}>
              {price ? `$${price?.toFixed(2)}` : '-'}
            </Text>
          </Flex>
        </Flex>

        <Button mt="42px" as="a" href="/swap" variant="secondary">
          Launch App
        </Button>
      </Flex>
    </>
  )
}

export default Hero
