import { Flex, Text, Button } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import useTheme from 'hooks/useTheme'
import { NextLinkFromReactRouter as RouterLink } from 'components/NextLink'
import CompositeImage from './CompositeImage'

const SalesSection: React.FC<React.PropsWithChildren> = () => {
  const { theme } = useTheme()
  const { t } = useTranslation()

  const images = {
    path: '/images/home/trade/',
    attributes: [
      { src: 'icon1', alt: t('BNB token') },
      { src: 'icon2', alt: t('BNB token') },
      { src: 'icon3', alt: t('BNB token') },
    ],
  }

  return (
    <Flex flexDirection="column">
      <Flex flexDirection={['column-reverse', null, null, 'row']} alignItems="center" justifyContent="center">
        <Flex
          flexDirection="column"
          flex="1"
          mr={[null, null, null, '64px']}
          alignSelf={['flex-start', null, null, 'center']}
        >
          <Text fontSize={['20px', null, null, '38px']} color="#060602" mb="10px">
            LiteSwap Ecosystem
          </Text>
          <Text fontSize={['12px', null, null, '22px']} color={theme.colors.primary}>
            Keep growing, Keep moving, Keep building
          </Text>
          <Text fontSize={['12px', null, null, '22px']} color={theme.colors.textSubtle} mb="24px">
            Integrates many excellent tools
          </Text>
          <Flex>
            <Button mr="16px">
              <RouterLink to="/launchpad">
                <Text color="card" bold fontSize="16px">
                  Explore all
                </Text>
              </RouterLink>
            </Button>
          </Flex>
        </Flex>
        <Flex
          height={['192px', null, null, '300px']}
          width={['192px', null, null, '350px']}
          mb={['24px', null, null, '0']}
        >
          <CompositeImage {...images} />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SalesSection
