import { Flex, Text, Button } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import useTheme from 'hooks/useTheme'
import { NextLinkFromReactRouter as RouterLink } from 'components/NextLink'
import CompositeImage from './CompositeImage'

const SalesSection: React.FC<React.PropsWithChildren> = () => {
  const { theme } = useTheme()
  const { t } = useTranslation()

  const images = {
    path: '/images/home/trade/',
    attributes: [
      { src: 'icon4', alt: t('BNB token') },
      { src: 'icon5', alt: t('USDT token') },
      { src: 'icon6', alt: t('BCC token') },
    ],
  }

  return (
    <Flex flexDirection="column">
      <Flex
        flexDirection={['column-reverse', null, null, 'row-reverse']}
        alignItems={['center', null, null, 'center']}
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          flex="1"
          ml={[null, null, null, '64px']}
          alignSelf={['flex-start', null, null, 'center']}
        >
          <Text fontSize={['20px', null, null, '32px']} fontWeight={800} color="">
            Born in the community
          </Text>
          <Text fontSize={['20px', null, null, '32px']} fontWeight={800} color="" mb="10px">
            Governed by the community
          </Text>
          <Text fontSize={['12px', null, null, '22px']} color={theme.colors.textSubtle} mb="24px">
            The LiteSwap is managed by a global community of XLITE holders and delegates.
          </Text>
          <Flex>
            <Button mr="16px" variant="secondary">
              {/* <RouterLink to="/xlite"> */}
              <Text color="card" bold fontSize="16px">
                GOT xLITE
              </Text>
              {/* </RouterLink> */}
            </Button>
          </Flex>
        </Flex>
        <Flex
          height={['192px', null, null, '300px']}
          width={['192px', null, null, '350px']}
          mb={['24px', null, null, '0']}
        >
          <CompositeImage {...images} />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SalesSection
